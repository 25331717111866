/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PaymentListFilter = (function() {

    /**
     * Namespace PaymentListFilter.
     * @exports PaymentListFilter
     * @namespace
     */
    var PaymentListFilter = {};

    PaymentListFilter.PaymentListFilterModel = (function() {

        /**
         * Properties of a PaymentListFilterModel.
         * @memberof PaymentListFilter
         * @interface IPaymentListFilterModel
         * @property {string|null} [dateFrom] PaymentListFilterModel dateFrom
         * @property {string|null} [dateTo] PaymentListFilterModel dateTo
         * @property {string|null} [invoiceNo] PaymentListFilterModel invoiceNo
         * @property {string|null} [creditor] PaymentListFilterModel creditor
         * @property {string|null} [sortColumn] PaymentListFilterModel sortColumn
         * @property {string|null} [sortOrder] PaymentListFilterModel sortOrder
         * @property {number|null} [pageIndex] PaymentListFilterModel pageIndex
         * @property {number|null} [pageSize] PaymentListFilterModel pageSize
         */

        /**
         * Constructs a new PaymentListFilterModel.
         * @memberof PaymentListFilter
         * @classdesc Represents a PaymentListFilterModel.
         * @implements IPaymentListFilterModel
         * @constructor
         * @param {PaymentListFilter.IPaymentListFilterModel=} [p] Properties to set
         */
        function PaymentListFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentListFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.dateFrom = null;

        /**
         * PaymentListFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.dateTo = null;

        /**
         * PaymentListFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.invoiceNo = null;

        /**
         * PaymentListFilterModel creditor.
         * @member {string|null|undefined} creditor
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.creditor = null;

        /**
         * PaymentListFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.sortColumn = null;

        /**
         * PaymentListFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.sortOrder = null;

        /**
         * PaymentListFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.pageIndex = null;

        /**
         * PaymentListFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        PaymentListFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * PaymentListFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentListFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentListFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentListFilterModel _creditor.
         * @member {"creditor"|undefined} _creditor
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_creditor", {
            get: $util.oneOfGetter($oneOfFields = ["creditor"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentListFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentListFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentListFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentListFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @instance
         */
        Object.defineProperty(PaymentListFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified PaymentListFilterModel message. Does not implicitly {@link PaymentListFilter.PaymentListFilterModel.verify|verify} messages.
         * @function encode
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @static
         * @param {PaymentListFilter.IPaymentListFilterModel} m PaymentListFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentListFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(10).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(18).string(m.dateTo);
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(26).string(m.invoiceNo);
            if (m.creditor != null && Object.hasOwnProperty.call(m, "creditor"))
                w.uint32(34).string(m.creditor);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified PaymentListFilterModel message, length delimited. Does not implicitly {@link PaymentListFilter.PaymentListFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @static
         * @param {PaymentListFilter.IPaymentListFilterModel} message PaymentListFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentListFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaymentListFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PaymentListFilter.PaymentListFilterModel} PaymentListFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentListFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PaymentListFilter.PaymentListFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.dateFrom = r.string();
                    break;
                case 2:
                    m.dateTo = r.string();
                    break;
                case 3:
                    m.invoiceNo = r.string();
                    break;
                case 4:
                    m.creditor = r.string();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a PaymentListFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PaymentListFilter.PaymentListFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PaymentListFilter.PaymentListFilterModel} PaymentListFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentListFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return PaymentListFilterModel;
    })();

    return PaymentListFilter;
})();

module.exports = $root;
