import FqApi from '@/services/fq-api'
//import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseUsedPartsInfoFilter(filter)
    const url = `paymentlist/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseUsedPartsInfoFilter(filter) {
    const entityProto = require('../../assets/proto/paymentlist/PaymentListFilter.proto')
    const filterMessage = entityProto.PaymentListFilter.PaymentListFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async GetCreditReturnMainId(returnNo) {
    const url = `paymentlist/returnmainid?returnNo=${returnNo}`
    const result = await FqApi.get(url)
    console.log('result: ' + result.data)
    return result.data
  },
  putEntity(id, entity) {
    return FqApi.put(`/paymentlist?creditorPaymentId=${id}`,entity)
  }
}
