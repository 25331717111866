<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <payment-list-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.creditorPaymentId"
                :class="{ 'is-selected': selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <a @click.prevent="gotoV1(entity, $event)">{{ entity.invoiceNoRef }}</a>
                </td>
                <td>{{ entity.vendorName }}</td>
                <td class="has-text-right">{{ entity.paid | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.taxWh | formatNumber($userInfo.locale) }}</td>
                <td>{{ $filters.formatDateTimezone(entity.payDate, $userInfo.locale) }}</td>
                <td>{{ entity.cheque }}</td>
                <td>{{ entity.bankAccount }}</td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[entity.factored == 0 ? 'has-text-success' : 'has-text-danger']">
                    <i class="mdi mdi-24px"
                      :class="[entity.factored == 1 ? 'mdi-close' : '']" />
                  </span>
                </td>
                <td class="has-text-right">{{ entity.gstPaid == null ? '0.00' : entity.gstAmount | formatNumber($userInfo.locale) }}</td>
                <td>{{ $filters.formatDateTimezone(entity.gstPaid, $userInfo.locale) }}</td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="gotoV1(entity, $event)"
                    data-tooltip="Show Invoice/Payment">
                    <span class="icon is-medium">
                      <i class="mdi mdi-file-document mdi-24px" />
                    </span>
                  </a>
                  <a class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                    v-if="checkInvoice(entity)"
                    data-tooltip="Click to delete"
                    @click="deleteButton(entity)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <advance-receipt-modal v-if="isLoadAdvPaymentModalActive"
      :active.sync="isLoadAdvPaymentModalActive"
      :show-icon="false"
      @close="closeModal()">
      <p slot="text-title">Advance Receipt Entry</p>
    </advance-receipt-modal>
    <payment-entry-advance-payment-modal v-if="isAdvancePaymentModalActive"
      :active.sync="isAdvancePaymentModalActive"
      v-model="entitySummaries"
      @close="closeAdvancePaymentModal" />
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Creditor Payment List</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ selectedInvoiceRef }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import PaymentListListSideMenu from './PaymentListListSideMenu'
import PaymentListValidation from './PaymentListValidation'
import PaymentListService from './PaymentListService'
import PaymentEntryAdvancePaymentModal from '@/views/paymententry/PaymentEntryAdvancePaymentModal'
import { Columns } from './columns'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { DateTime } from 'luxon'
import store from '@/store'
import { ConfirmModal } from '@/components/BulmaModal'
import _cloneDeep from 'lodash/cloneDeep'
import DebtorReceiptService from './../debtorreceipt/DebtorReceiptService'

export default {
  name: 'PaymentListListView',
  components: {
    AppDetailHeader,
    [PaymentListListSideMenu.name]: PaymentListListSideMenu,
    BulmaTable,
    ConfirmModal,
    PaymentEntryAdvancePaymentModal
  },
  mixins: [DateTimeFiltersMixin, PaymentListValidation, NumberFiltersMixin, StoreMixin],
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      filter: {
        dateFrom: '',
        dateTo: '',
        invoiceNo: '',
        creditor: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false,
      isAdvancePaymentModalActive: false,
      isLoadAdvPaymentModalActive: false,
      isConfirmModalActive: false,
      selectedInvoiceRef: null
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getEntitySummaries()
    this.getAccessRoutes()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    // generateUrl(id) {
    //   return `quickinvoices/${id}`
    // },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    edit(id, event) {},
    async getAccessRoutes() {
      this.accessRoutes = await DebtorReceiptService.getAccessRoutes()
    },
    async gotoV1(entity) {
      let receiptEntryRoute = this.accessRoutes.records.filter(obj => {
        return obj.code === 'A013'
      })

      let paymentEntryRoute = this.accessRoutes.records.filter(obj => {
        return obj.code === 'A048'
      })

      switch (true) {
        case entity.paymentType === 1: //sundry
          //window.open(`${process.env.VUE_APP_ROOT_URI}/Modules/SundryCreditorView/SundryCreditorView.aspx?paymentID=${paymentId}&RPId=${invoiceId}`, '_self')
          await store.dispatch('sundrycreditors/editStoreItem', entity.creditorInvoiceID)
          this.setSundryCreditorReturnRoute(this.$route)
          break

        case entity.paymentType === 2 && paymentEntryRoute[0].version.toUpperCase() !== '2': //payment entry V1
          window.open(
            `${process.env.VUE_APP_ROOT_URI}/Modules/PaymentEntryView/PaymentEntryDetails.aspx?pID=${this.$guid.createShortGuid(
              entity.creditorPaymentID
            )}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/paymentlist`
          )
          break
        case entity.paymentType === 2 && paymentEntryRoute[0].version.toUpperCase() === '2': //payment entry V2
          this.setPaymentEntryReadOnlyView(true)
          this.setPaymentEntryReturnRoute(this.$route)
          this.editStorePaymentEntry(entity.creditorPaymentID)
          break
        case entity.paymentType === 3 && paymentEntryRoute[0].version.toUpperCase() !== '2': //advance payment V1
          window.open(
            `${process.env.VUE_APP_ROOT_URI}/Modules/PaymentEntryView/PaymentEntry.aspx?paymentID=${this.$guid.createShortGuid(
              entity.creditorInvoiceID
            )}&RPId=${this.$guid.createShortGuid(entity.creditorInvoiceID)}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/paymentlist`,
            '_self'
          )
          break
        case entity.paymentType === 3 && paymentEntryRoute[0].version.toUpperCase() === '2': //advance payment V2
          this.setPaymentEntryReadOnlyView(true)
          await this.editStoreAdvancePayment(entity.creditorPaymentID)
          this.isAdvancePaymentModalActive = true
          break
        //Factor V2
        case entity.paymentType === 5 && receiptEntryRoute[0].version.toUpperCase() === '2':
          await store.dispatch('factorinvoice/editStoreItem', entity.creditorInvoiceID)
          this.setFactorInvoiceReturnRoute(this.$route)
          break

        //Factor V1
        case entity.paymentType === 5 && receiptEntryRoute[0].version.toUpperCase() === 'DEFAULT':
          window.open(
            `${process.env.VUE_APP_ROOT_URI}/Modules/ReceiptEntryView/FactorDetails.aspx?RPId=${entity.creditorInvoiceID}&RedirectUrl=${process.env.VUE_APP_BASE_URI}/paymentlist`,
            '_self'
          )
          break

        case entity.paymentType === 6:
          if (entity.prefix !== 'RFC') {
            window.open(
              `${process.env.VUE_APP_ROOT_URI}/Modules/CreditorView/DealerCreditEntry.aspx?paymentID=${entity.creditorPaymentID}&RPId=${entity.creditorInvoiceID}`
            )
          } else {
            let creditReturnMainId = await PaymentListService.GetCreditReturnMainId(entity.invoiceNoRef)
            window.open(`${process.env.VUE_APP_ROOT_URI}/Modules/ReturnPartsView/ReturnParts.aspx?RPId=${creditReturnMainId}`)
          }
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Payment List', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.dateFrom = ''
      this.filter.dateTo = ''
      this.filter.invoiceNo = ''
      this.filter.creditor = ''
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await PaymentListService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    checkInvoice(entity) {
      return entity.prefix == 'AJ' || entity.prefix == 'RFC' || entity.factored == 1 || entity.gstPaid !== null ? false : true
    },
    async deleteButton(entity) {
      await this.getStoreItem(entity)
      this.selectedEntity = _cloneDeep(entity)
      this.selectedInvoiceRef = entity.invoiceNoRef
      this.isConfirmModalActive = true
    },
    async deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.selectedEntity.deleted = true
        await this.saveSnapshot(_cloneDeep(this.selectedEntity))
        this.selectedEntity.deepDiff = JSON.stringify(this.snapshotDiff)
        await PaymentListService.putEntity(this.selectedEntity.creditorPaymentID, this.selectedEntity)
        this.getEntitySummaries()
      }
    },
    print() {
      let showingMsg = ''
      if (this.filter.dateFrom === '' && this.filter.dateTo === '') {
        showingMsg = 'SHOWING ALL PAYMENTS'
      } else {
        let fromDate = this.filter.dateFrom === '' ? '-' : DateTime.fromISO(this.filter.dateFrom).toLocaleString()
        let toDate = this.filter.dateTo === '' ? '-' : DateTime.fromISO(this.filter.dateTo).toLocaleString()
        showingMsg = 'Showing Payments dated between ' + fromDate + ' and ' + toDate
      }
      const params = {
        FROMDATE: this.filter.dateFrom === '' ? '1754-12-31' : this.filter.dateFrom,
        TODATE: this.filter.dateTo === '' ? '9999-12-30' : this.filter.dateTo,
        REPORTTITLE: 'PAYMENT LIST',
        COMPANYNAME: this.$userInfo.companyName,
        Showing: showingMsg,
        CompanyID: this.$userInfo.companyId,
        InvoiceNo: this.filter.invoiceNo,
        Creditor: this.filter.creditor,
        SortColumn: this.filter.sortColumn,
        SortDirection: this.filter.sortOrder,
        PageIndex: 1,
        PageSize: this.totalRows,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      const emailer = new Emailer()
      emailer.subject = 'PaymentList'
      emailer.reportName = 'PaymentList'
      this.addEmailer(emailer)

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    closeAdvancePaymentModal() {
      this.isAdvancePaymentModalActive = false
    }
  }
}
</script>
