import FqApi from '@/services/fq-api'
//import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseDebtorsReceiptsFilter(filter)
    const url = `debtorsreceipts/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseDebtorsReceiptsFilter(filter) {
    const entityProto = require('../../assets/proto/debtorreceipt/DebtorReceiptFilter.proto')
    const filterMessage = entityProto.DebtorReceiptFilter.DebtorReceiptFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAccessRoutes() {
    const url = 'accesssetup/companyaccess'
    const result = await FqApi.get(url)
    return result.data
  },
  putEntity(id, entity) {
    return FqApi.put(`/debtorsreceipts?invoicePaymentId=${id}`,entity)
  }
}
