import { mapGetters, mapActions } from 'vuex'

const moduleName = 'paymentlist'

export default {
  computed: {
    ...mapGetters(moduleName, {
      snapshots: 'snapshots',
      returnRoute: 'returnRoute',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'saveSnapshot']),
    ...mapActions('factorinvoice', { setFactorInvoiceReturnRoute: 'setReturnRoute' }),
    ...mapActions('sundrycreditors', { setSundryCreditorReturnRoute: 'setReturnRoute' }),
    ...mapActions('paymententries', {
      setPaymentEntryReturnRoute: 'setReturnRoute',
      editStoreAdvancePayment: 'editStoreItem',
      editStorePaymentEntry: 'editStoreItemPaymentEntry',
      setPaymentEntryReadOnlyView: 'setReadOnlyView'
    }),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
