import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Inv No.', 'invoiceNoRef', true, 'desc', 'left'),
  new TableColumn('Creditor Name', 'vendorName', true, 'desc', 'left'),
  new TableColumn('Paid Amt', 'paid', true, 'asc', 'right'),
  new TableColumn('Tax With-Held', 'taxWh', true, 'asc', 'right'),
  new TableColumn('Paid Date', 'payDate', true, 'asc', 'left'),
  new TableColumn('Cheque No', 'cheque', true, 'asc', 'left'),
  new TableColumn('Bank', 'bankAccount', true, 'asc', 'left'),
  new TableColumn('Factorer', 'factored', true, 'asc', 'centered'),
  new TableColumn('GST Reported', 'gstAmount', true, 'asc', 'right'),
  new TableColumn('Report Date', 'gstPaid', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }